import styled from "styled-components"

export const ContainerWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 0 2rem;
  position: relative;
  background-color: transparent;

`
