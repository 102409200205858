export default {
  fonts: {
    main: "Alice",
    second: "SeoulHangang",
    code: "Roboto , monospace",
  },
  colors: {
    main: "#19446C",
    dark: "#444444",
    light: "#FFFCF8"
  }
}
