import styled from "styled-components"

export const ContentWrapper = styled.main`
  min-height: 8rem;
  padding: 2rem 0;
  height: 110rem;
  background-color: transparent;
  position: relative;
  top: 100px;
`
